.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  background-color: #444;
  color: #fff;
  padding: 10px 20px;
  border-bottom: 2px solid #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header h1 {
  margin: 0;
  font-size: 32px;
  font-weight: bold;
}

.app-icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.content {
  display: flex;
  flex: 1;
}

.left-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25%;
  padding: 10px;
  height: 150vh;
  border-right: 1px solid #ccc;
  overflow: visible; /* 추가 */
}

.selected-items {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.selected-item {
  border: 2px solid #514b37;
  cursor: pointer;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.selected-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.selected-items-container {
  padding: 10px;
  border-radius: 8px;
  width: 250px;
}

.right-panel {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  box-sizing: border-box;
  overflow: visible; /* 추가 */
}

.filter-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-label {
  display: flex;
  align-items: center;
}

.filter-label input {
  margin-right: 5px;
}

.item-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
}

.item {
  position: relative; /* Ensure the parent has a position set */
  width: 64px;
  height: 64px;
  margin: 0;
  padding: 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  transition: transform 0.2s;
  cursor: pointer;
  z-index: 1; /* 추가 */
}

.into-item {
  width: 40px;
  height: 40px;
}

.from-item {
  width: 40px;
  height: 40px;
}

.item:hover {
  transform: scale(1.05);
}

.item-details {
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

input[type="text"]:focus {
  border-color: #007bff;
  outline: none;
}

.search-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.search-container label {
  margin-right: 10px;
  font-size: 16px;
  color: #333;
  white-space: nowrap;
}

.empty-slot {
  width: 64px;
  height: 64px;
  border: 2px solid #514b37;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  cursor: not-allowed;
}

.slot-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 15px;
  width: 100%; /* Ensure the container takes full width */
  max-width: 250px; /* Adjust as needed for your design */
  margin: 0 auto; /* Center the grid */
}

.bold {
  font-weight: bold;
}

.reset-button {
  width: 80px;
  height: 30px;
  background-color: #3b523c;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reset-button:hover {
  background-color: #45a049;
}

.filter-button {
  padding: 2px 4px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  cursor: pointer;
  font-size: 16px;
  transition: color 0.1s;
}

.filter-button:hover {
  background-color: #e0e0e0;
}

.filter-button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.filter-button.active:hover {
  background-color: #003d80;
}

.colloq-name {
  color: gray;
  font-size: 0.7em;
}

.jack-container {
  display: flex;
  align-items: center;
}

.jack-image {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.jack-description {
  max-width: 400px;
}

body, .some-class {
  font-family: 'Noto Sans KR', sans-serif;
}

.active-stats-list, .inactive-stats-list, .total-stats-list {
  list-style-type: none;
  padding: 0;
}

.active-stat-item, .inactive-stat-item, .total-stat-item {
  font-weight: bold;
  margin-bottom: 5px;
}

.active-stat-item {
  color: green;
}

.inactive-stat-item {
  color: rgb(121, 0, 0);
}

.total-stat-item {
  color: #333;
  display: flex;
  align-items: center;
}

.stat-icon {
  margin-right: 5px;
  color: #007bff;
}

.empty-slots-message {
  color: #ff6347;
  font-weight: normal;
  margin-top: 5px;
  text-align: left;
  font-size: 18px;
}

.item-details-overlay {
  position: fixed; /* fixed를 유지 */
  width: 550px; /* 오버레이 너비 조정 */
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 15px;
  border-radius: 8px;
  z-index: 1000;
  pointer-events: none; /* 추가 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Mobile styles */
@media (max-width: 768px) {
  .App {
    flex-direction: column;
  }

  .header {
    text-align: center;
  }

  .content {
    flex-direction: column;
  }

  .left-panel, .right-panel {
    width: 100%; /* 모바일에서 전체 너비로 설정 */
    padding: 10px;
  }

  .selected-items-container, .total-stats, .jack-of-all-trades {
    margin-bottom: 20px;
    width: 100%;
  }

  .item-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 10px;
  }

  .item {
    display: flex;
    justify-content: center;
  }

  .filter-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .filter-button {
    margin: 5px;
  }

  .search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .search-container input {
    width: 80%;
    margin-top: 10px;
  }

  .footer {
    text-align: center;
    padding: 10px;
  }

  .item-details-overlay {
    display: none;
  }

  /* 추가된 모바일 스타일 */
  .reset-button {
    width: 100%;
    margin-top: 10px;
  }

  .slot-container {
    grid-template-columns: repeat(2, 1fr); /* 모바일에서 2열로 변경 */
  }
}

.selected-items.slot-container {
  display: flex;
  align-items: center;
  background-color: #333; /* Dark background */
  padding: 10px;
  border-radius: 5px;
  width: 250px;
}

.total-gold {
  color: #fff; /* White text for contrast */
  margin-right: 10px; /* Space between total gold and button */
}

.version-selector {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.version-select {
  padding: 5px 10px;
  border: 2px solid #333;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  font-size: 14px;
  cursor: pointer;
}

.version-select:hover {
  border-color: #007bff;
}

.version-select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}

/* 모바일 대응 */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: 10px;
  }

  .version-selector {
    margin-left: 0;
    margin-top: 10px;
  }
}
